import fastdom from 'fastdom';
import type { ReactNode, RefCallback } from 'react';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { trackModuleViewed } from 'src/analytics/events/trackModuleViewed';

interface ContextProviderProps {
  children: ReactNode;
}

interface TrackedSection {
  ref: HTMLElement;
  moduleLevel: number;
  moduleName: string;
  moduleIndex: number;
}

interface RegisterRefProps {
  ref: HTMLElement;
  moduleName: string;
}

interface TrackedSectionsContext {
  registerRef: (props: RegisterRefProps) => void;
  unRegisterRef: (moduleName: string) => void;
  trackedSections: TrackedSection[];
}

export const TrackedSectionsContext = createContext<TrackedSectionsContext>({
  registerRef: () => {},
  unRegisterRef: () => {},
  trackedSections: [],
});

export function TrackedSectionsProvider({ children }: ContextProviderProps) {
  const [trackedSections, setTrackedSections] = useState<TrackedSection[]>([]);

  const registerRef = useCallback((props: RegisterRefProps) => {
    const { ref, moduleName } = props;

    fastdom.measure(() => {
      setTrackedSections(currentSections => {
        if (currentSections.some(section => section.ref === ref)) {
          return currentSections;
        }

        // Sort sections by their position on the page
        const newSections = [
          ...currentSections,
          {
            ref,
            moduleName,
            moduleLevel: 1,
            // Temporary index value
            moduleIndex: 0,
          },
        ].sort(
          (a, b) =>
            a.ref.getBoundingClientRect().top -
            b.ref.getBoundingClientRect().top
        );

        // Assign index values based on sorted position
        const indexedSections = newSections.map((section, i) => ({
          ...section,
          moduleIndex: i + 1,
        }));

        return indexedSections;
      });
    });
  }, []);

  const unRegisterRef = useCallback((moduleName: string) => {
    setTrackedSections(currentSections => {
      if (!currentSections.some(section => section.moduleName === moduleName)) {
        return currentSections;
      }

      // Only sections with moduleLevel 1 (meaning they are on the main page and not nested behind dynamic logic) should be
      // considered in this scroll tracking mechanism. A section will only be unregistered when the user navigates
      // to a new page, therefore re-assigning indexes here is unnecessary.
      return currentSections.filter(
        section =>
          section.moduleName !== moduleName && section.moduleLevel === 1
      );
    });
  }, []);

  const contextValue = {
    registerRef,
    unRegisterRef,
    trackedSections,
  };

  useEffect(() => {
    if (trackedSections.length === 0) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            fastdom.measure(() => {
              const section = trackedSections.find(s => s.ref === entry.target);

              if (section) {
                trackModuleViewed({
                  moduleIndex: section.moduleIndex,
                  moduleName: section.moduleName,
                  moduleLevel: 1,
                  moduleLocation: Math.round(
                    section.ref.getBoundingClientRect().top + window.scrollY
                  ),
                });
              }
            });
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    trackedSections.forEach(section => observer.observe(section.ref));
    return () => observer.disconnect();
  }, [trackedSections]);

  return (
    <TrackedSectionsContext.Provider value={contextValue}>
      {children}
    </TrackedSectionsContext.Provider>
  );
}

export function useTrackedSections() {
  return useContext(TrackedSectionsContext);
}

export function useTrackedSectionRef<ElementType extends HTMLElement>(
  moduleName: string
) {
  const { registerRef, unRegisterRef } = useTrackedSections();

  // See https://18.react.dev/reference/react-dom/components/common#ref-callback
  // for more information on RefCallbacks.
  const sectionRef: RefCallback<ElementType> = useCallback(
    node => {
      if (node) {
        registerRef({ ref: node, moduleName });
      } else {
        unRegisterRef(moduleName);
      }
    },

    [moduleName, registerRef, unRegisterRef]
  );

  return sectionRef;
}
